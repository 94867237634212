/* .syllabus-container {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  padding: 20px;
  flex-wrap: wrap; 
  background: linear-gradient(180deg, rgba(20, 20, 40, 1), rgba(50, 0, 80, 1)); 
}

.carousel-container, .faq-section {
  flex: 1; 
  max-width: 50%; 
  padding: 20px;
  box-sizing: border-box; 
}


.carousel-container h2 {
  background: linear-gradient(45deg, #9881d7, #f890ea , #e28aa0, #ffb58d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.carousel-container p {
  color: white;
}

.faq-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 8.8%;
  height: 425px;
}


.faq-item {
  margin-bottom: 15px;
}

.faq-item h3 {
  font-size: 1.1em;
  margin-bottom: 5px;
  color: #333;
  transition: color 0.3s ease; 
}

.faq-item h3:hover {
  color: #ff69b4; 
}

.faq-item p {
  font-size: 1em;
  color: #555;
}


.carousel-container .carousel img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.carousel .legend {
  display: none;
}
 */
 .syllabus-container {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  padding: 20px;
  flex-wrap: wrap;
  background: linear-gradient(180deg, rgba(20, 20, 40, 1), rgba(50, 0, 80, 1)); 
}

.text-content {
  text-align: center;
  width: 100%; /* Ensures it's full width, outside the container */
  margin-bottom: 20px;
}
.text-content h2 {
  background: linear-gradient(45deg, #9881d7, #f890ea , #e28aa0, #ffb58d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}
.text-content p {
  color: white;
  font-size: 1.5em;
}

.carousel-container, .faq-section {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

/* Carousel */
.carousel-container {
  display: flex;
  flex-direction: column;
}



/* FAQ section */
.faq-container {
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1.5%;
  max-width: 50%;
  height: 520px;
}

.faq-item {
  margin-bottom: 50px;
}

.faq-item h3 {
  font-size: 1.1em;
  margin-bottom: 5px;
  color: #333;
  transition: color 0.3s ease;
}

.faq-item h3:hover {
  color: #ff69b4;
}

.faq-item p {
  font-size: 1em;
  color: #555;
}

/* Carousel images */
.carousel-container .carousel img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.carousel .legend {
  display: none;
}

@media (max-width: 1024px) {
  .syllabus-container {
    flex-direction: column;
    padding: 10px; 
  }

  .carousel-container, .faq-section {
    max-width: 100%; 
    margin-bottom: 20px; 
  }
}

@media (max-width: 768px) {
  .carousel-container {
    height: 350px; 
  }

  .faq-section {
    height: auto; 
  }
}

@media (max-width: 480px) {
  .carousel-container {
    height: 250px; 
  }

  .faq-item h3 {
    font-size: 1rem;
  }

  .faq-item p {
    font-size: 0.9rem; 
}
}
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
}

.header {
  background: linear-gradient(180deg, rgba(20, 20, 40, 1), rgba(50, 0, 80, 1)); /* Gradient background */  
  padding: 20px;
  text-align: center;
  color: white;
}

.header h1 {
  font-size: 2.5em;
  background: linear-gradient(45deg, #9881d7,#f890ea , #e28aa0, #ffb58d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  
  }
  /* Custom Navbar CSS */
.custom-navbar {
  background-color: #2d145e; /* Custom background color (e.g., orange) */
  color: #fff; /* Custom text color */

}

.custom-navbar .navbar-nav .nav-link {
  color: #fff; /* White text color for the navbar links */
}

/* Change color of nav-link on hover */
.custom-navbar .navbar-nav .nav-link:hover {
  color: #f0f0f0; /* Slightly lighter color on hover */
}

.custom-navbar .navbar-toggler-icon {
  background-color: #fff; /* White color for the hamburger icon */
}

  
.course-details h2 {
  font-size: 2em ;
  color: #008080;

}

.course-details ul {
  list-style-type: none;
  padding: 0;
}

.course-details ul li {
  font-size: 1.5em;
  color: #333;
  margin: 10px 0;
}

.sign-up {
  /* background-color: rgba(39, 19, 102, 0.86); */
  background: linear-gradient(180deg, rgba(50, 0, 80, 1) , rgba(20, 20, 40, 1)); /* Gradient background */  

  padding: 40px;
  text-align: center;
}

.sign-up h2 {
  font-size: 2em;
  background: linear-gradient(45deg, #9881d7,#f890ea , #e28aa0, #ffb58d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
margin-bottom: 20px; 
}

form {
  display: inline-block;
  margin-top: 20px;
}

input[type="text"], input[type="email"] {

 padding: 10px;
  width: 40%; 
  font-size: 1em;
  border: 2px solid #f890ea;
  border-radius: 5px; 
  margin-right: 10px;

  
}

button {

  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #9881d7; 
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;

}

button:hover {
  background-color: #f890ea; 
}

.confirmation-message {
  color: white;
  font-size: 1.2em;
  margin-top: 20px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

}

.confirmation-message.visible {
  opacity: 1;
}


.about-container {
  padding: 40px;
  background-color: #f9f9f9;
  color: #2d145e;
}

.about-devpursuit {
  margin-bottom: 40px;
}

.about-devpursuit h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.about-devpursuit p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.core-values {
  list-style: none;
  padding: 0;
}

.core-values li {
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.meet-instructor h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}



.instructor-details {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.instructor-details p {
  margin-top: 5%;
}

.instructor-image {
  width: 250px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
}

.instructor-details p {
  font-size: 1.2rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .instructor-details {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .instructor-image {
    margin-bottom: 25px;
  }
}
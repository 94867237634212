/* PrivacyPolicy.css */

.privacy-policy-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 30px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
  }
  
  .privacy-policy-header {
   text-align: center;
    margin-bottom: 40px;
  }

  .privacy-policy-header h1 {
    color: #333;
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 10px;
}
  
  
  .privacy-policy-header p {
    font-size: 1rem;
    color: #888;
  }
  
  .privacy-policy-content {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
  }
  
  h2.section-title, h3.section-subheading, h4 {
    color: #2C3E50;
  }
  
  h2.section-title {
    font-size: 1.75rem;
    margin-top: 40px;
  }
  
  h3.section-subheading {
    font-size: 1.5rem;
    margin-top: 20px;
  }
  
  h4 {
    font-size: 1.25rem;
    margin-top: 15px;
  }
  
  ul.definition-list, ul.cookie-list {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  ul.definition-list li, ul.cookie-list li {
    margin: 5px 0;
  }
  
  a {
    color: #1e70b2;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  






/* General Styling for the Mentorship Pitch */
.mentorship-pitch {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

/* Header Section */
.header {
  text-align: center;
}

.hero-image {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin: 20px 0;
  animation: fadeIn 1s ease-in-out;
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #4CAF50;
}

.intro-text {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #fb96d8;
  max-width: 800px;
  margin: 0 auto;
}

/* What I Offer Section */
.what-i-offer {
  margin-top: 40px;
}

.Mentorship-section-heading {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #333;
}

.mentorship-level {
  margin-bottom: 25px;
}

.mentorship-level h3 {
  font-size: 1.5rem;
  color: #0e0f0f;
  margin-bottom: 10px;
}

.mentorship-level ul {
  list-style-type: none;
  padding-left: 0;
}

.mentorship-level li {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.mentorship-level li strong {
  color: #f890ea;
}

/* FAQ Section */
.Mentorshipfaq {
  margin-top: 40px;
}

.Mentorshipfaq-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  transition: background-color 0.3s;
}

.Mentorshipfaq-item:hover {
  background-color: #f0f0f0;
}

.Mentorshipfaq-question {
  cursor: pointer;
  font-size: 1.3rem;
  color: #0e0f0f;
}

.Mentorshipfaq-question:hover {
  color: #f890ea;
}

.Mentorshipfaq-answer {
  margin-top: 10px;
  font-size: 1rem;
  color: #ededed;
}

 /* .calendly-badge-content{
  background-color: #9881d7;
  color: #fff;
}
.calendly-badge-content:hover {
  background-color: #f890ea;
} */

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Responsive Styles */

/* Medium Devices */
@media (max-width: 768px) {
  .heading {
    font-size: 2rem;
  }

  .intro-text {
    font-size: 1.1rem;
  }

  .mentorship-level h3 {
    font-size: 1.3rem;
  }

  .mentorship-level li {
    font-size: 1rem;
  }

  .section-heading {
    font-size: 1.5rem;
  }

  .hero-image {
    width: 100%;
    max-width: 600px;
  }

  /* Adjust button for smaller screens */
  .calendly-button {
    font-size: 16px;
    padding: 10px 20px;
  }
}

/* Small Devices */
@media (max-width: 480px) {
  .heading {
    font-size: 1.7rem;
  }

  .hero-image {
    width: 100%;
    max-width: none;
  }

  .intro-text {
    font-size: 1rem;
  }

  .mentorship-level li {
    font-size: 0.9rem;
  }

  .Mentorshipfaq-question {
    font-size: 1.2rem;
  }

  /* Calendly button adjustments */
  .calendly-button {
    font-size: 14px;
    padding: 8px 15px;
  }
}

/* Additional Media Queries for extra small screens */
@media (max-width: 375px) {
  .heading {
    font-size: 1.5rem;
  }

  .intro-text {
    font-size: 0.9rem;
  }

  .calendly-button {
    font-size: 14px;
    padding: 8px 15px;
    width: 100%;
  }
}

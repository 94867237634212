/* Container for the ebook gallery */
.ebook-gallery {
    text-align: center;
    padding: 20px;
  }
  
  .ebook-header h1 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .ebook-header .subheading {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 30px;
  }
  
  /* Grid layout for the ebook items */
  .ebook-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); /* Flexibly adjust to screen size */
    gap: 20px;
    justify-items: center;  /* Center items within each grid cell */
    margin-top: 20px;
  }
  
  /* Style for each ebook item */
  .ebook-item {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    max-width: 300px;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .ebook-item img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .ebook-info h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .ebook-info p {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #333;
  }
  
  .download-button {
    padding: 10px 20px;
    border: none;
    background-color: #9881d7;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .download-button:hover {
    background-color: #f890ea;
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .ebook-gallery {
      padding: 15px;
    }
  
    .ebook-header h1 {
      font-size: 1.8rem;
    }
  
    .ebook-header .subheading {
      font-size: 1.1rem;
    }
  
    .ebook-item {
      max-width: 90%;  /* Allow more space on smaller screens */
    }
  
    .ebook-item h3 {
      font-size: 1.1rem;
    }
  
    .ebook-item p {
      font-size: 0.9rem;
    }
  
    .download-button {
      font-size: 0.9rem;
      padding: 10px 15px;
    }
  }
  
  @media (max-width: 480px) {
    .ebook-header h1 {
      font-size: 1.5rem;
    }
  
    .ebook-header .subheading {
      font-size: 1rem;
    }
  
    .ebook-item h3 {
      font-size: 1rem;
    }
  
    .ebook-item p {
      font-size: 0.8rem;
    }
  
    .download-button {
      font-size: 0.8rem;
      padding: 8px 12px;
    }
  }
  
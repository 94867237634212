.footer {
    background: linear-gradient(180deg, rgba(20, 20, 40, 1), rgba(50, 0, 80, 1)); /* Gradient background */  
   color: #fff;
    padding: 30px 20px;
}

.footer-content {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap on smaller screens */
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-section {
    flex: 1; /* Each section will take equal space */
    margin: 10px;
    min-width: 200px; /* Ensures sections don't shrink too much on small screens */
}

.footer-section h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
    text-align: center;
    color: #f890ea;
}

.footer-section p {
    margin: 5px 0;
    text-align: center;
    color:#fff
}
.footer-bottom p {
   
    color:#fff
}

.privacy-link:link {
    color: white;
    background-color: transparent;
    text-decoration: none;
  }
  .privacy-link:visited {
    color: white;
    background-color: transparent;
    text-decoration: none;
  }
  .privacy-link:hover {
    color: #f890ea;
    background-color: transparent;
    text-decoration: underline;
  }
  .privacy-link:active {
    color: plum;
    background-color: transparent;
    text-decoration: underline;
  }
  

.footer-logo {
    width: 150px;
    margin: 0 auto 20px; /* Centers the logo horizontally */
    display: block;
}

.social-media {
    display: flex;
    justify-content: center; /* Centers icons horizontally */
    gap: 15px; /* Adds spacing between icons */
}

.social-media a {
    color: #fff;
    font-size: 1.5em;
    transition: color 0.3s, transform 0.3s;
}

.social-media a:hover {
    color: #f890ea;
    transform: scale(1.2);
}

.footer-bottom {
    text-align: center;
    padding-top: 20px;
    font-size: 0.9em;
    color: #fff;
    border-top: 1px solid #fff;
    margin-top: 20px;
}

/* Media Queries for Responsiveness */

/* Medium screens (tablets) */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center; /* Centers items vertically */
    }

    .footer-section {
        text-align: center; /* Center-aligns each section */
    }

    .footer-logo {
        margin-bottom: 10px;
    }
}

/* Small screens (mobile devices) */
@media (max-width: 480px) {
    .footer-section h4 {
        font-size: 1.1em; /* Slightly smaller heading font on small screens */
    }

    .footer-logo {
        width: 120px; /* Reduce logo size on small screens */
    }

    .social-media a {
        font-size: 1.3em; /* Reduce icon size on small screens */
    }
}

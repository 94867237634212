/* Desktop and larger screens */
.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px;
    gap: 20px;
    flex-wrap: wrap; /* Allow items to wrap if needed */
}

.editor-container {
    flex: 1 1 60%; /* Editor takes 60% of the container */
    min-width: 300px;
    margin-right: 20px;
    max-width: 100%; /* Ensures the editor doesn't overflow the container */
}

.editor_container_tite {
    font-size: 2.5em;
    background: linear-gradient(45deg, #9881d7, #f890ea, #e28aa0, #ffb58d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.guidance {
    flex: 1 1 35%; /* Guidance takes 35% of the container */
    min-width: 250px;
    height: 600px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-sizing: border-box;
}

/* Mobile layout */
@media (max-width: 768px) {
    .container {
        flex-direction: column; /* Stack items vertically */
        align-items: center;
    }

    .editor-container, .guidance {
        flex: 1 1 100%; /* Make both sections 100% width */
        max-width: 100%;
    }

    .guidance {
        margin-top: 20px;
    }
}

/* Common styles for output section */
.output {
    margin-top: 20px;
    background-color: #f4f4f4;
    padding: 10px;
    border: 1px solid #ccc;
    width: 100%;
}

/* Mobile Warning */
.mobile-warning {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 14px;
    text-align: center;
}

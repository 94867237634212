.testimonial-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .testimonials h2{
    text-align: center;
    background: linear-gradient(45deg, #9881d7,#f890ea , #e28aa0, #ffb58d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding-bottom: 50px;
  padding-top: 40px;
  }
  .testimonials {
    background: linear-gradient(180deg,#320050,#141428);
  }

  .testimonial {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    margin: 10px;
    transition: transform 0.3s ease;
    position: relative;
    animation: fadeIn 1.5s ease-in-out; 

  }
  
  .testimonial:hover {
    transform: scale(1.05);
  }
  
  .quote {
    font-size: 1.3em;
    font-style: italic;
    color: #555;
    line-height: 1.6;
    font-family: 'Georgia', serif;
    margin-bottom: 15px;
  }
  
  .author {
    font-size: 1em;
    font-weight: bold;
    color: #333;
    text-align: right;
    font-family: 'Montserrat', sans-serif;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
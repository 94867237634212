.congrats-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  .congrats-header{
    font-size: 2.5em;
    background: linear-gradient(45deg, #9881d7,#f890ea , #e28aa0, #ffb58d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    
  }
  
  .congrats-content {
    flex: 2;
    padding-right: 20px;
    max-width: 600px;
  }
  
  .featured-image-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .featured-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }


.call-to-action {
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}


.call-to-action a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.back-to-exercises button{
text-decoration: none;
}

.return-button {
   
  
    background-color: #9881d7; 
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .return-button:hover {
    background-color: #f890ea; 
  }
  

  @media (max-width: 768px) {
    .congrats-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .congrats-content {
      padding-right: 0;
    }
  
    .featured-image-container {
      justify-content: center;
      margin-top: 20px;
    }
  }
  